body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

body,
html {
  height: 100%;
  line-height: 1.8;
}

/* Full height image header */
.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-image: url("https://www.w3schools.com/w3images/mac.jpg");
  min-height: 100%;
}

.w3-bar .w3-button {
  padding: 16px;
}
.show {
  transform: translateX(0);
  display: block !important;
  transition: 0.5s;
}
.hide {
  transform: translateX(0);
  display: none !important;
  transition: 0.5s;
}
.padding-48 {
  padding: 48px;
}
.padding-24-48 {
  padding: 24px 48px;
}
.minPageHight {
  min-height: 60vh;
  margin-top: 5rem !important;
}
